export const SearchUUID = {
  attributeName: 'data-search-uuid',

  init() {
    const uuidStorageKey = 'search-uuid';

    const restoreUUIDFromStorage = () => {
      try {
        const storageValue = localStorage.getItem(uuidStorageKey);
        if (!storageValue) return;

        const now = new Date().getTime();
        const { uuid, timestamp } = JSON.parse(storageValue);

        // if timestamp is from less than 10 seconds ago, set the uuid
        if (now - timestamp < 10000) {
          this.set(uuid);
        }
      } catch (error) {
        console.error('Error extracting UUID from storage', error);
      } finally {
        localStorage.removeItem(uuidStorageKey);
      }
    };

    const storeUUIDFromClick = (e) => {
      if (!e.target.closest('[data-uuid-passthrough]')) return;

      const contextUUID = this.get(e.target);
      if (!contextUUID) return;

      // store the uuid in session storage
      const storageObject = {
        uuid: contextUUID,
        timestamp: new Date().getTime(),
      };

      localStorage.setItem(uuidStorageKey, JSON.stringify(storageObject));
    };

    // extract UUID from click event for use in modals/dialogs
    const extractUUIDFromClick = (e) => {
      const contextUUID = this.get(e.target);
      if (!contextUUID) return;

      this.set(contextUUID);
    };

    document.addEventListener('click', (e) => {
      extractUUIDFromClick(e);
      storeUUIDFromClick(e);
    }, true);

    restoreUUIDFromStorage();
  },

  set(uuid, htmlElement = null) {
    const targetEl = htmlElement || document.querySelector('body');
    targetEl?.setAttribute(this.attributeName, uuid);
  },

  get(htmlElement = null) {
    const targetEl = htmlElement || document.querySelector('body');
    if (!targetEl) return '';

    return targetEl.closest(`[${this.attributeName}]`)?.getAttribute(this.attributeName);
  },

  clear(htmlElement = null) {
    if (!htmlElement) return;

    htmlElement.removeAttribute(this.attributeName);
  },
};
